import '../base-modal.css';
import './construction-progress-modal.css';

import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DateSelect from '../../components/date-select/date-select';
import SidebarInputField from '../sidebar/components/sidebar-input-field/sidebar-input-field';
import { createConstructionProgressItem, IConstructionProgressItem, updateConstructionProgressItem, uploadConstructionProgressDocument } from '../../services/constructionProgressService';
import { UploadedFile } from '../../components/file-upload/file-upload';
import FileUploadSection from '../../components/file-upload-section/file-upload-section';
import { format } from 'date-fns';

interface ModalProps {
    property_id: number;
    construction_progress_item: IConstructionProgressItem | null;
    onClose: () => void;
    onCreated: (item: IConstructionProgressItem) => void;
    onUpdated: (item: IConstructionProgressItem) => void;
}

const ConstructionProgressModal: React.FC<ModalProps> = ({ property_id, construction_progress_item, onClose, onCreated, onUpdated }) => {
    const { t } = useTranslation();

    // States
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [publishedDate, setPublishedDate] = useState<Date | null>(null);
    const [description, setDescription] = useState<string>("");
    const [percentage, setPercentage] = useState<string>("");
    const [dldReports, setDldReports] = useState<UploadedFile[]>([]);

    // Error States
    const [publishedDateError, setPublishedDateError] = useState<string>("");
    const [descriptionError, setDescriptionError] = useState<string>("");

    useEffect(() => {
        if (construction_progress_item) {
            setPublishedDate(new Date(construction_progress_item.published_date));
            setDescription(construction_progress_item.description);
            setPercentage(formatNumber(construction_progress_item.percentage));
        }
    }, [construction_progress_item]);

    // Functions
    const formatNumber = (value: number | undefined | null) => {
        if (value === undefined || value === null) return "";
        const formattedValue = new Intl.NumberFormat('en-US', {
            minimumFractionDigits: 0,
            maximumFractionDigits: 2,
        }).format(value);

        return formattedValue;
    };

    const onHandleSaveConstructionPlanItem = useCallback(async () => {
        let isValid = true;

        // Check if the description is not empty
        if (!description.trim()) {
            setDescriptionError(t("invalid_input.description_empty"));
            isValid = false;
        }

        // Check if the published date is not empty
        if (!publishedDate) {
            setPublishedDateError(t("invalid_input.published_date_empty"));
            isValid = false;
        }

        // Check if the percentage is not empty
        if (!percentage) {
            setPercentage(t("invalid_input.construction_progress_percentage_empty"));
            isValid = false;
        }

        if (isValid) {
            try {
                setIsLoading(true);
                if (construction_progress_item) {
                    const updatedItem = await updateConstructionProgressItem(
                        construction_progress_item.id,
                        format(publishedDate!, 'yyyy-MM-dd'),
                        description,
                        Number(percentage)
                    );
                    onUpdated(updatedItem);
                } else {
                    const createdItem = await createConstructionProgressItem(
                        property_id,
                        format(publishedDate!, 'yyyy-MM-dd'),
                        description,
                        Number(percentage)
                    );

                    // Upload selected DLD Report
                    if (dldReports.length > 0) {
                        const createdDocument = await uploadConstructionProgressDocument(createdItem.id, dldReports[0].file);
                        createdItem.document = createdDocument;
                    }
                    onCreated(createdItem);
                }
            } catch (error) {
                console.log(`Error while creating a new construction progress item for project (${property_id}):`, error);
            } finally {
                setIsLoading(false);
            }
        }
    }, [publishedDate, description, percentage, dldReports, construction_progress_item, property_id, t, onCreated]);

    return (
        <div className="modal-backdrop">
            <div className="modal-box">
                <h3 className="input-modal-title mb-4">{t(construction_progress_item ? "modals.construction_progress.edit_title" : "modals.construction_progress.add_title")}</h3>
                <p className="mb-4">{t("modals.construction_progress.message")}</p>
                <DateSelect
                    id="add-construction-progress-publish-date"
                    label={t("labels.published_on")}
                    placeholder={t("placeholders.select_date")}
                    date={publishedDate ?? null}
                    onChange={(date) => {
                        setPublishedDate(date);
                        setPublishedDateError("");
                    }}
                    onClear={() => {
                        setPublishedDate(null);
                        setPublishedDateError("");
                    }}
                    errorMessage={publishedDateError}
                    labelOnTop
                    required
                />

                <SidebarInputField
                    id="add-construction-progress-description"
                    type="text"
                    label={t("labels.description")}
                    placeholder="Foundational Work Complete"
                    value={description}
                    errorMessage={descriptionError}
                    onClear={() => {
                        setDescription("");
                        setDescriptionError("");
                    }}
                    onChange={(value: string) => {
                        setDescription(value);
                        setDescriptionError("");
                    }}
                    labelOnTop
                    required
                />

                <SidebarInputField
                    id="add-construction-progress-percentage"
                    type="number"
                    label={t("labels.percentage")}
                    placeholder="10%"
                    value={percentage}
                    onClear={() => setPercentage("")}
                    onChange={setPercentage}
                    labelOnTop
                    required
                />

                {/* UPLOAD DLD REPORT */}
                <FileUploadSection
                    fileUploadId="add-construction-progress-upload-dld-report"
                    title={t("modals.construction_progress.upload_dld_report")}
                    documents={construction_progress_item?.document ? [construction_progress_item.document] : []}
                    uploadedFiles={dldReports}
                    onUpdateUploadedFiles={setDldReports}
                    maxFilesToUpload={1}
                    isHeadlineLarge={false}
                    removePadding
                    hasEditPermission
                    allowedTypes=".pdf"
                />

                <div className="text-end mt-4">
                    <button
                        className={`btn btn-primary add-btn me-2 ${isLoading ? 'disabled' : ''}`}
                        onClick={onHandleSaveConstructionPlanItem}
                        disabled={isLoading}
                    >
                        {isLoading ? (
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true" />
                        ) : (
                            t(construction_progress_item ? "buttons.save" : "buttons.create")
                        )}
                    </button>
                    {!isLoading && (
                        <button className="btn btn-dark close-btn" onClick={() => onClose()}>{t("buttons.close")}</button>
                    )}
                </div>
            </div>
        </div>
    );
};

export default ConstructionProgressModal;
