import './lease-payments-table.css';
import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { formatDate, isBefore } from 'date-fns';
import { formatPaymentMethod, formatPaymentPurpose, IPayment, PaymentStatus, uploadPaymentDocument } from '../../../../services/paymentService';
import { formatMoney } from '../../../../utils/helpers';
import { IoAdd, IoReceipt, IoSettingsOutline } from 'react-icons/io5';
import FileUpload, { UploadedFile } from '../../../../components/file-upload/file-upload';
import FileCard from '../../../../components/file-card/file-card';
import CustomButton from '../../../../components/custom-button/custom-button';
import useAlertStore from '../../../../stores/alertStore';
import DeleteFileModal from '../../../../modals/delete-file-modal/delete-file-modal';
import { IDocument } from '../../../../services/documentService';
import LeasePaymentSidebarModal from '../../../../modals/sidebar/lease-payment-sidemodal/lease-payment-sidemodal';
import useEmployeeStore from '../../../../stores/employeeStore';
import useEditPermission from '../../../../hooks/useEditPermission';
import { EmployeeRole } from '../../../../services/employeeService';

interface LeasePaymentsTableProps {
    unit_account_id: number;
    lease_id: number;
    payments: IPayment[];
    onPaymentsUpdated: (payments: IPayment[]) => void;
}

const LeasePaymentsTable: React.FC<LeasePaymentsTableProps> = ({ unit_account_id, lease_id, payments, onPaymentsUpdated }) => {
    const { t } = useTranslation();

    // Stores
    const employeeRoleId = useEmployeeStore(state => state.employee?.role_id);
    const hasEditPermission = useEditPermission(employeeRoleId, [EmployeeRole.ADMINISTRATOR, EmployeeRole.FINANCE_MANAGER]);
    const showAlert = useAlertStore(state => state.showAlert);

    // States
    const [expandedRow, setExpandedRow] = useState<number | null>(null);
    const [file, setFile] = useState<UploadedFile | null>(null);
    const [isDocumentUploading, setIsDocumentUploading] = useState<boolean>(false);
    const [isDeleteFileModalVisible, setIsDeleteFileModalVisible] = useState<IDocument | null>(null);
    const [isSidebarModalVisible, setIsSidebarModalVisible] = useState<boolean>(false);
    const [selectedPayment, setSelectedPayment] = useState<IPayment | null>(null);

    const isOverdue = (dueDate: string, paymentDate: string | null) => {
        const today = new Date();
        const todayWithoutTime = new Date(today.setHours(0, 0, 0, 0));
        const dueDateWithoutTime = new Date(new Date(dueDate).setHours(0, 0, 0, 0));
        return isBefore(dueDateWithoutTime, todayWithoutTime) && !paymentDate;
    };

    const toggleRowExpansion = (payment_id: number) => {
        setExpandedRow(expandedRow === payment_id ? null : payment_id);
        setFile(null);
    };

    const onClickUploadDocument = useCallback(async (payment_id: number) => {
        if (file) {
            try {
                setIsDocumentUploading(true);
                const document = await uploadPaymentDocument(payment_id, file.file);
                const updatedPayments = payments.map((payment) =>
                    payment.payment_id === payment_id
                        ? { ...payment, document: { ...document } }
                        : { ...payment }
                );
                setFile(null);
                onPaymentsUpdated([...updatedPayments]);
            } catch (error) {
                console.log('Error while uploading payment document:', error);
                // @ts-ignore
                showAlert("error", error.message);
            } finally {
                setIsDocumentUploading(false);
            }
        }
    }, [file, payments, onPaymentsUpdated]);

    const onHandleDeleteDocument = useCallback((document: IDocument) => {
        const updatedPayments = payments.map((payment) =>
            payment.document && payment.document.document_id === document.document_id
                ? { ...payment, document: null }
                : { ...payment }
        );
        onPaymentsUpdated([...updatedPayments]);

        if (selectedPayment) {
            setSelectedPayment({ ...selectedPayment, document: null });
        }
    }, [payments, selectedPayment, onPaymentsUpdated, setSelectedPayment]);

    return (
        <>
            <div className="lease-payments">
                <div className="d-flex justify-content-between align-items-center mb-3">
                    <h5 className="title">({payments.length}) {t("pages.lease_detail.lease_payments.title")}</h5>
                    {hasEditPermission && (
                        <button className="btn btn-create-payment" onClick={() => setIsSidebarModalVisible(true)}>
                            {t("buttons.create")}
                        </button>
                    )}
                </div>
                {payments.length > 0 ? (
                    <div className="lease-payments-table-responsive">
                        <table className="lease-payments-table">
                            <thead>
                                <tr>
                                    <th>{t("payment_table.due_date")}</th>
                                    <th>{t("payment_table.paid_date")}</th>
                                    <th>{t("payment_table.amount")} (AED)</th>
                                    <th>{t("payment_table.method")}</th>
                                    <th>{t("payment_table.purpose")}</th>
                                    <th>{t("payment_table.status")}</th>
                                    {hasEditPermission && (
                                        <th><IoSettingsOutline /></th>
                                    )}
                                </tr>
                            </thead>
                            <tbody>
                                {payments.map(payment => (
                                    <React.Fragment key={payment.payment_id}>
                                        <tr onClick={() => toggleRowExpansion(payment.payment_id)}>
                                            <td
                                                style={isOverdue(payment.due_date, payment.payment_date) ? { fontWeight: "600" } : {}}>
                                                {formatDate(payment.due_date, "dd.MM.yyyy")}
                                            </td>
                                            <td>
                                                {payment.payment_date ? formatDate(payment.payment_date, "dd.MM.yyyy") : "-"}
                                            </td>
                                            <td>
                                                <span
                                                    className={`status-badge-${payment.payment_date ? "paid" : isOverdue(payment.due_date, payment.payment_date) ? "overdue" : "unpaid"}`}
                                                >
                                                    {formatMoney(payment.amount)}
                                                </span>
                                            </td>
                                            <td>
                                                <div>
                                                    <span style={{ textDecoration: payment.status === PaymentStatus.BOUNCED ? 'line-through' : 'none' }}>
                                                        {formatPaymentMethod(payment.method, t)}
                                                    </span>
                                                    {payment.cheque_no && payment.cheque_no.length > 0 && (
                                                        <span className="cheque-number">({payment.cheque_no})</span>
                                                    )}
                                                </div>
                                            </td>
                                            <td>{formatPaymentPurpose(payment.purpose, t)}</td>
                                            <td>
                                                <span className={`status-badge-${payment.payment_date ? "paid" : isOverdue(payment.due_date, payment.payment_date) ? "overdue" : "unpaid"}`}>
                                                    {payment.status === PaymentStatus.BOUNCED
                                                        ? t("labels.bounced")
                                                        : payment.payment_date
                                                            ? t("labels.paid")
                                                            : isOverdue(payment.due_date, payment.payment_date)
                                                                ? t("labels.overdue")
                                                                : t("labels.unpaid")
                                                    }
                                                </span>
                                            </td>
                                            {hasEditPermission && (
                                                <td
                                                    className="settings-icon"
                                                    onClick={(event) => {
                                                        event.stopPropagation();
                                                        setSelectedPayment(payment);
                                                        setIsSidebarModalVisible(true);
                                                    }}
                                                >
                                                    <IoSettingsOutline />
                                                </td>
                                            )}
                                        </tr>
                                        {/* Expandable content row */}
                                        {expandedRow === payment.payment_id && (
                                            <tr className="expandable-content">
                                                <td colSpan={7}>
                                                    <div className="expanded-content-box">
                                                        {payment.document && (
                                                            <FileCard
                                                                index={payment.document.document_id}
                                                                documentId={payment.document.document_id}
                                                                fileName={payment.document.file_name}
                                                                filePath={payment.document.file_path}
                                                                onClickRemove={() => setIsDeleteFileModalVisible(payment.document)}
                                                                progress={100}
                                                                uploaded
                                                            />
                                                        )}
                                                        {file && (
                                                            <FileCard
                                                                index={1}
                                                                fileName={file.filename}
                                                                progress={file.progress}
                                                                onClickRemove={() => setFile(null)}
                                                                uploaded={false}
                                                            />
                                                        )}
                                                        {!payment.document && !file && (
                                                            <FileUpload
                                                                id={`lease-payment-document-${payment.payment_id}`}
                                                                onFileSelected={(files) => setFile(files.length > 0 ? files[0] : null)}
                                                                maxFilesToUpload={1}
                                                                currentFileCount={payment.document ? 1 : 0}
                                                            />
                                                        )}
                                                        {!payment.document && file && (
                                                            <CustomButton
                                                                className="mt-3 me-3"
                                                                color="blue"
                                                                size="small"
                                                                title="Upload"
                                                                icon={IoAdd}
                                                                isLoading={isDocumentUploading}
                                                                disabled={isDocumentUploading}
                                                                onClick={() => onClickUploadDocument(payment.payment_id)}
                                                            />
                                                        )}
                                                        <CustomButton
                                                            className="mt-3"
                                                            color="green"
                                                            size="small"
                                                            title="Print Receipt"
                                                            icon={IoReceipt}
                                                            isLoading={false}
                                                            onClick={() => { }}
                                                        />
                                                        {payment.remark && payment.remark.length > 0 && (
                                                            <p className="remark">{t("labels.remark")}: {payment.remark}</p>
                                                        )}
                                                    </div>
                                                </td>
                                            </tr>
                                        )}
                                    </React.Fragment>
                                ))}
                            </tbody>
                        </table>
                    </div>
                ) : (
                    <div className="lease-payments-table-card">
                        <p className="no-payments-text">{t("pages.lease_detail.lease_payments.no_payments")}</p>
                    </div>
                )}
            </div>

            {/* DELETE DOCUMENT MODAL */}
            {isDeleteFileModalVisible && (
                <DeleteFileModal
                    document_id={isDeleteFileModalVisible.document_id}
                    onClose={(deleted: boolean) => {
                        if (deleted) {
                            onHandleDeleteDocument(isDeleteFileModalVisible);
                        }
                        setIsDeleteFileModalVisible(null);
                    }}
                />
            )}

            {/* LEASE PAYMENT SIDEBAR MODAL */}
            {hasEditPermission && (
                <LeasePaymentSidebarModal
                    unit_account_id={unit_account_id}
                    lease_id={lease_id}
                    payment={selectedPayment}
                    isVisible={isSidebarModalVisible}
                    onClose={() => {
                        setIsSidebarModalVisible(false);
                        setSelectedPayment(null);
                    }}
                    onDeleteDocument={onHandleDeleteDocument}
                    onPaymentCreated={(payment) => {
                        payments.push(payment);
                        onPaymentsUpdated(payments);
                    }}
                    onPaymentUpdated={(updatedPayment) => {
                        const updatedPayments = payments.map((payment) =>
                            payment.payment_id === updatedPayment.payment_id
                                ? { ...updatedPayment }
                                : { ...payment }
                        );
                        onPaymentsUpdated([...updatedPayments]);
                    }}
                    onPaymentDeleted={(deletedPayment) => {
                        onPaymentsUpdated([...payments.filter(payment => payment.payment_id !== deletedPayment.payment_id)]);
                    }}
                />
            )}
        </>
    );
};

export default LeasePaymentsTable;
