import { create } from "zustand";
import { devtools, persist } from "zustand/middleware";
import { DocumentStatus } from "../services/documentService";

interface DocumentFilterState {
  selectedState: DocumentStatus | null;
  setSelectedState: (state: DocumentStatus | null) => void;
}

const useDocumentFilterStore = create<DocumentFilterState>()(
  devtools(
    persist(
      (set, get) => ({
        selectedState: null,
        setSelectedState: (selectedState) => set({ selectedState }),
      }),
      {
        name: "document-filter-storage",
      }
    )
  )
);

export default useDocumentFilterStore;
