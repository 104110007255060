import '../sidemodal.css';

import React, { useCallback, useEffect, useState } from 'react';
import { IoClose } from 'react-icons/io5';
import CustomButton from '../../../components/custom-button/custom-button';
import SidebarInputField from '../components/sidebar-input-field/sidebar-input-field';
import SidebarSelectField, { SidebarSelectFieldOptions } from '../components/sidebar-select-field/sidebar-select-field';
import useAlertStore from '../../../stores/alertStore';
import { useTranslation } from 'react-i18next';
import { UploadedFile } from '../../../components/file-upload/file-upload';
import { IUnit, UnitPurposeType, UnitSoldBy, createUnit, formatSoldBy, uploadUnitDocuments } from '../../../services/unitService';
import ExpandableSection from '../../../components/expandable-section/expandable-section';
import { useProperties } from '../../../hooks/useProperties';
import FileUploadSection from '../../../components/file-upload-section/file-upload-section';

interface UnitSidebarModalProps {
    isVisible: boolean;
    onClose: () => void;
    mutate: (data?: any, shouldRevalidate?: boolean) => Promise<any>;
}

const UnitSidebarModal: React.FC<UnitSidebarModalProps> = ({ isVisible, onClose, mutate }) => {
    const { t } = useTranslation();

    const purposeTypeOptions: SidebarSelectFieldOptions[] = Object.values(UnitPurposeType).map((purpose) => ({
        label: purpose.replace(/-/g, ' ').replace(/\b\w/g, (p) => p.toUpperCase()),
        value: purpose,
    }));

    const soldByOptions: SidebarSelectFieldOptions[] = Object.values(UnitSoldBy).map((soldBy) => ({
        label: formatSoldBy(soldBy),
        value: soldBy,
    }));

    // Stores
    const showAlert = useAlertStore(state => state.showAlert);

    // States
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // Unit general information
    const [purposeType, setPurposeType] = useState<string>(purposeTypeOptions[0].value);
    const [soldBy, setSoldBy] = useState<string>(soldByOptions[0].value);
    const [annualRent, setAnnualRent] = useState<string>("");
    const [unitNumber, setUnitNumber] = useState<string>("");
    const [floor, setFloor] = useState<string>("");
    const [squareFeet, setSquareFeet] = useState<string>("");
    const [bedroom, setBedroom] = useState<string>("");
    const [bathroom, setBathroom] = useState<string>("");

    // Unit conditions
    const [underRenovation, setUnderRenovation] = useState<boolean>(false);
    const [underDispute, setUnderDispute] = useState<boolean>(false);
    const [furnished, setFurnished] = useState<boolean>(true);

    // Unit uitilities information
    const [dewa, setDewa] = useState<string>("");
    const [municipalityNumber, setMunicipalityNumber] = useState<string>("");
    const [gasNumber, setGasNumber] = useState<string>("");

    // Unit files
    const [files, setFiles] = useState<UploadedFile[]>([]);

    // Error States
    const [annualRentError, setAnnualRentError] = useState<string>("");
    const [unitNumberError, setUnitNumberError] = useState<string>("");
    const [floorError, setFloorError] = useState<string>("");
    const [squareFeetError, setSquareFeetError] = useState<string>("");
    const [bedroomError, setBedroomError] = useState<string>("");
    const [bathroomError, setBathroomError] = useState<string>("");

    const [property, setProperty] = useState<string>("");
    const [error, setError] = useState<string>("");

    // Hooks
    const { properties, isReachingEnd: isReachingPropertiesEnd } = useProperties();

    // Clean states
    useEffect(() => {
        if (!isVisible) {
            setPurposeType(purposeTypeOptions[0].value);
            setSoldBy(soldByOptions[0].value);
            setUnitNumber("");
            setFloor("");
            setSquareFeet("");
            setBedroom("");
            setBathroom("");
            setUnderRenovation(false);
            setUnderDispute(false);
            setFurnished(false);
            setDewa("");
            setMunicipalityNumber("");
            setGasNumber("");

            setUnitNumberError("");
            setFloorError("");
            setSquareFeetError("");
            setBedroomError("");
            setBathroomError("");
            setError("");
            setFiles([]);
        }
    }, [isVisible]);

    const onFileSelected = (selectedFiles: UploadedFile[]) => {
        setFiles(selectedFiles);
    };

    // Create Unit
    const onHandleCreateUnit = useCallback(async () => {
        let isValid = true;

        // Check if the minimum annual rent is not empty
        if (!annualRent.trim()) {
            setAnnualRentError(t("invalid_input.annual_rent_empty"));
            isValid = false;
        }

        // Check if the unit number is not empty
        if (!unitNumber.trim()) {
            setUnitNumberError(t("invalid_input.unit_number_empty"));
            isValid = false;
        }

        // Check if the floor is not empty
        if (!floor.trim()) {
            setFloorError(t("invalid_input.floor_empty"));
            isValid = false;
        }

        // Check if the square feet is not empty
        if (!squareFeet.trim()) {
            setSquareFeetError(t("invalid_input.square_feet_empty"));
            isValid = false;
        }

        // Check if the bedroom is not empty
        if (!bedroom.trim()) {
            setBedroomError(t("invalid_input.bedroom_empty"));
            isValid = false;
        }

        // Check if the bathroom is not empty
        if (!bathroom.trim()) {
            setBathroomError(t("invalid_input.bathroom_empty"));
            isValid = false;
        }

        if (isValid) {
            try {
                setError("");
                setIsLoading(true);

                const createdUnit = await createUnit(Number(property), unitNumber, Number(squareFeet), Number(bedroom), Number(bathroom), floor, underRenovation, underDispute, furnished, dewa, municipalityNumber, gasNumber, Number(annualRent), purposeType, soldBy);

                // Upload files
                if (files.length > 0) {
                    await uploadUnitDocuments(
                        createdUnit.unit_id,
                        files.map(uploadedFiles => uploadedFiles.file),
                        null,
                        [],
                        files.map(file => file.internal_document ? "true" : "false")
                    );
                }

                mutate(
                    (data: IUnit[][]) => {
                        if (!data) return [];
                        return [
                            [createdUnit, ...data[0]],
                            ...data.slice(1)
                        ];
                    },
                    false
                );

                onClose();
                showAlert("success", t("modals.create_unit.create_success_message"));
            } catch (error) {
                // @ts-ignore
                setError(error.message);
            } finally {
                setIsLoading(false);
            }
        }
    }, [purposeType, soldBy, annualRent, unitNumber, floor, squareFeet, bedroom, bathroom, underRenovation, underDispute, furnished, dewa, municipalityNumber, gasNumber, property, files, mutate, onClose, showAlert, t]);

    return (
        <div className={`sidebar-modal-backdrop ${isVisible ? 'visible' : ''}`}>
            <div className={`sidebar-modal ${isVisible ? 'visible' : ''}`}>
                <div className="d-flex align-items-center justify-content-between my-3">
                    <h4 className="sidebar-modal-headline ms-3">{t("modals.create_unit.title")}</h4>
                    <button className="btn" onClick={onClose}>
                        <IoClose size={20} />
                    </button>
                </div>
                <div className="sidebar-modal-divider" />
                <div className="sidebar-modal-content">
                    <div className="sidebar-modal-scrollable-content">

                        {/* PROPERTY SELECTION */}
                        <SidebarSelectField
                            id="property"
                            label={t("labels.property")}
                            value={property}
                            onChange={setProperty}
                            options={properties.map(property => ({
                                label: property.property_name,
                                value: property.property_id.toString()
                            }))}
                            isEndReached={isReachingPropertiesEnd}
                            required
                            labelOnTop
                        />

                        <div className="row">
                            <div className="col-12 col-xxl-6">
                                <SidebarSelectField
                                    id="unit-creation-purpose-type-selection"
                                    label={t("labels.purpose_type")}
                                    value={purposeType}
                                    onChange={setPurposeType}
                                    options={purposeTypeOptions}
                                    labelOnTop
                                    required
                                />
                            </div>
                            <div className="col-12 col-xxl-6">
                                <SidebarSelectField
                                    id="unit-creation-sold-by-selection"
                                    label={t("labels.sold_by")}
                                    value={soldBy}
                                    onChange={setSoldBy}
                                    options={soldByOptions}
                                    labelOnTop
                                    required
                                />
                            </div>
                        </div>

                        {/* GENERAL SECTION */}
                        <ExpandableSection title={t("modals.create_unit.general_title")} className="mt-4">
                            <div className="row">
                                <div className="col-12 col-xxl-6">
                                    <SidebarInputField
                                        id="minimum-annual-rent-number"
                                        type="number"
                                        label={t("labels.annual_rent")}
                                        placeholder="70,000.00 AED"
                                        value={annualRent}
                                        errorMessage={annualRentError}
                                        onClear={() => setAnnualRent("")}
                                        onChange={(value: string) => {
                                            setAnnualRent(value);
                                            setAnnualRentError("");
                                        }}
                                        required
                                        labelOnTop
                                    />
                                </div>
                                <div className="col-12 col-xxl-6">
                                    <SidebarInputField
                                        id="unit-number"
                                        type="text"
                                        label={t("labels.unit_number")}
                                        placeholder="G05"
                                        value={unitNumber}
                                        errorMessage={unitNumberError}
                                        onClear={() => setUnitNumber("")}
                                        onChange={(value: string) => {
                                            setUnitNumber(value);
                                            setUnitNumberError("");
                                        }}
                                        required
                                        labelOnTop
                                    />
                                </div>
                                <div className="col-12 col-xxl-6">
                                    <SidebarInputField
                                        id="floor"
                                        type="text"
                                        label={t("labels.floor")}
                                        placeholder="10"
                                        value={floor}
                                        errorMessage={floorError}
                                        onClear={() => setFloor("")}
                                        onChange={(value: string) => {
                                            setFloor(value);
                                            setFloorError("");
                                        }}
                                        required
                                        labelOnTop
                                    />
                                </div>
                                <div className="col-12 col-xxl-6">
                                    <SidebarInputField
                                        id="square-feet"
                                        type="number"
                                        label={t("labels.square_feet")}
                                        placeholder="870"
                                        value={squareFeet}
                                        errorMessage={squareFeetError}
                                        onClear={() => setSquareFeet("")}
                                        onChange={(value: string) => {
                                            setSquareFeet(value);
                                            setSquareFeetError("");
                                        }}
                                        required
                                        labelOnTop
                                    />
                                </div>
                                <div className="col-12 col-xxl-6">
                                    <SidebarInputField
                                        id="bedroom"
                                        type="number"
                                        label={t("labels.bedroom")}
                                        placeholder="2"
                                        value={bedroom}
                                        errorMessage={bedroomError}
                                        onClear={() => setBedroom("")}
                                        onChange={(value: string) => {
                                            setBedroom(value);
                                            setBedroomError("");
                                        }}
                                        required
                                        labelOnTop
                                    />
                                </div>
                                <div className="col-12 col-xxl-6">
                                    <SidebarInputField
                                        id="bathroom"
                                        type="number"
                                        label={t("labels.bathroom")}
                                        placeholder="1"
                                        value={bathroom}
                                        errorMessage={bathroomError}
                                        onClear={() => setBathroom("")}
                                        onChange={(value: string) => {
                                            setBathroom(value);
                                            setBathroomError("");
                                        }}
                                        required
                                        labelOnTop
                                    />
                                </div>
                            </div>
                        </ExpandableSection>

                        {/* UNIT CONDITIONS SECTION */}
                        <ExpandableSection title={t("pages.unit_detail.unit_condition_title")} className="mt-4">
                            <div className="row gy-3">
                                <div className="col-6">
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" checked={underRenovation} onChange={() => setUnderRenovation(!underRenovation)} />
                                        <label className="form-check-label">{t("labels.under_renovation")}</label>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" checked={underDispute} onChange={() => setUnderDispute(!underDispute)} />
                                        <label className="form-check-label">{t("labels.under_dispute")}</label>
                                    </div>
                                </div>
                                <div className="col-6">
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" checked={furnished} onChange={() => setFurnished(!furnished)} />
                                        <label className="form-check-label">{t("labels.furnished")}</label>
                                    </div>
                                </div>
                            </div>
                        </ExpandableSection>

                        {/* UTILITIES SECTION */}
                        <ExpandableSection title={t("modals.create_unit.utilities_title")} className="mt-4">
                            <SidebarInputField
                                id="dewa-number"
                                type="text"
                                label={t("labels.dewa_number")}
                                placeholder="E92K2712"
                                value={dewa}
                                onClear={() => setDewa("")}
                                onChange={(value: string) => {
                                    setDewa(value);
                                }}
                            />
                            <SidebarInputField
                                id="gas-number"
                                type="text"
                                label={t("labels.gas_number")}
                                placeholder="6372621"
                                value={gasNumber}
                                onClear={() => setGasNumber("")}
                                onChange={(value: string) => {
                                    setGasNumber(value);
                                }}
                            />
                            <SidebarInputField
                                id="municipality-number"
                                type="text"
                                label={t("labels.municipality_number")}
                                placeholder="92736182"
                                value={municipalityNumber}
                                onClear={() => setMunicipalityNumber("")}
                                onChange={(value: string) => {
                                    setMunicipalityNumber(value);
                                }}
                            />
                        </ExpandableSection>

                        {/* UPLOAD DOCUMENTS SECTION */}
                        <FileUploadSection
                            fileUploadId="create-unit-upload-other-documents"
                            title={t("modals.create_unit.upload_documents")}
                            documents={[]}
                            uploadedFiles={files}
                            onUpdateUploadedFiles={onFileSelected}
                            allowInternalDocument
                            isHeadlineLarge={false}
                            removePadding
                            hasEditPermission
                        />
                    </div>
                    <div className="store-investor-btn">
                        {error && (
                            <p className="text-danger">{error}</p>
                        )}
                        <CustomButton
                            className="w-100"
                            title={t("modals.create_unit.create_button")}
                            isLoading={isLoading}
                            onClick={onHandleCreateUnit}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UnitSidebarModal;
