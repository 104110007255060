import { SortOrder } from "../@types";
import { API_URL } from "../utils/constants";
import { IConstructionProgressItem } from "./constructionProgressService";
import { ILease } from "./leaseService";
import { IPaymentPlanItem } from "./paymentPlanService";
import { IUnit, UnitSortBy } from "./unitService";

export interface IProperty {
  property_id: number;
  property_name: string;
  property_image: string;
  property_location: string;
  property_map_link: string;
  email: string | null;
  phone_number: string | null;
  plot_no: number;
  plot_purchased_price: number;
  plot_size: number;
  total_build_up_area_size: number;
  registration_number: string;
  under_construction: boolean;
  handover: boolean;
  created_at: string;
  payment_plan_items: IPaymentPlanItem[];
  construction_progress_items: IConstructionProgressItem[];
}

export interface IPropertyDashboard {
  property: IProperty;
  totalUnits: number;
  rentedUnits: number;
  totalOccupants: number;
  totalAvailableSoonUnits: number;
  availableSoonLeases: ILease[];
  availableUnits: IUnit[];
  rentThisMonth: number;
  depositThisMonth: number;
  petDepositThisMonth: number;
}

export const fetchProperties = async (url: string): Promise<IProperty[]> => {
  const response = await fetch(url, {
    method: "GET",
    credentials: "include",
  });

  const data = await response.json();
  if (!response.ok) throw new Error(data.message);

  return data.properties;
};

export const constructPropertiesUrl = (
  page?: number,
  pageSize?: number
): string => {
  let queryParams = new URLSearchParams();

  if (page !== undefined) {
    queryParams.append("page", page.toString());
  }
  if (pageSize !== undefined) {
    queryParams.append("pageSize", pageSize.toString());
  }

  return `${API_URL}/properties?${queryParams.toString()}`;
};

export const fetchProperty = async (propertyId: number): Promise<IProperty> => {
  const response = await fetch(`${API_URL}/properties/${propertyId}`, {
    method: "GET",
    credentials: "include",
  });

  const data = await response.json();
  if (!response.ok) throw new Error(data.message);

  return data;
};

export const fetchPropertyDashboard = async (
  propertyId: number
): Promise<IPropertyDashboard> => {
  const response = await fetch(
    `${API_URL}/properties/${propertyId}/dashboard`,
    {
      method: "GET",
      credentials: "include",
    }
  );

  const data = await response.json();
  if (!response.ok) throw new Error(data.message);

  return data;
};

export const updateProperty = async (
  property_id: number,
  email: string | null,
  phone_number: string | null
): Promise<IProperty> => {
  const response = await fetch(`${API_URL}/properties/${property_id}`, {
    method: "PATCH",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      email,
      phone_number,
    }),
  });

  const data = await response.json();
  if (response.status === 200) return data;
  else throw new Error(data.message);
};

export const fetchPropertyAvailableUnits = async (
  url: string
): Promise<IUnit[]> => {
  const response = await fetch(url, {
    method: "GET",
    credentials: "include",
  });

  const data = await response.json();
  if (!response.ok) throw new Error(data.message);

  return data.available_units;
};

export const constructPropertyAvailableUnitsUrl = (
  propertyId: number,
  search: string,
  page?: number,
  pageSize?: number,
  bedrooms?: string,
  bathrooms?: string,
  minimum_annual_rent?: string,
  sortBy?: UnitSortBy,
  sortOrder?: SortOrder
): string => {
  let queryParams = new URLSearchParams();

  if (search.trim().length > 0) {
    queryParams.append("search", encodeURIComponent(search));
  }
  if (propertyId !== null) {
    queryParams.append("propertyId", propertyId.toString());
  }
  if (page !== undefined) {
    queryParams.append("page", page.toString());
  }
  if (pageSize !== undefined) {
    queryParams.append("pageSize", pageSize.toString());
  }
  if (bedrooms !== undefined && bedrooms.length > 0) {
    queryParams.append("bedrooms", bedrooms);
  }
  if (bathrooms !== undefined && bathrooms.length > 0) {
    queryParams.append("bathrooms", bathrooms);
  }
  if (minimum_annual_rent !== undefined && minimum_annual_rent.length > 0) {
    queryParams.append("minimum_annual_rent", minimum_annual_rent);
  }
  if (sortBy !== undefined) {
    queryParams.append("sortBy", sortBy);
  }
  if (sortOrder !== undefined) {
    queryParams.append("sortOrder", sortOrder);
  }

  return `${API_URL}/properties/${propertyId}/available-units?${queryParams.toString()}`;
};
