import './unit-table.css';

import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { IUnit, UnitSortBy, exportUnitCsv, formatUnitPurposeType } from '../../../../services/unitService';
import UnitStatusBadge from '../../../../components/unit-status-badge/unit-status-badge';
import CustomButton from '../../../../components/custom-button/custom-button';
import { FaSortUp, FaSortDown, FaSort } from 'react-icons/fa';
import { SortOrder } from '../../../../@types';

interface UnitTableProps {
    units: IUnit[];
    onClickUnit: (unit_id: number) => void;
    onSortChange: (sortColumn: UnitSortBy, sortDirection: SortOrder) => void;
    selectedSortColumn: UnitSortBy | null;
    selectedSortDirection: SortOrder | null;
}

const UnitTable: React.FC<UnitTableProps> = ({
    units,
    onClickUnit,
    onSortChange,
    selectedSortColumn,
    selectedSortDirection
}) => {
    const { t } = useTranslation();

    const [selectedUnits, setSelectedUnits] = useState<Set<number>>(new Set());
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const handleSort = (column: UnitSortBy) => {
        const direction = selectedSortColumn === column && selectedSortDirection === SortOrder.ASC ? SortOrder.DESC : SortOrder.ASC;
        onSortChange(column, direction);
    };

    const handleCheckboxChange = (unitId: number) => {
        setSelectedUnits(prevSelected => {
            const newSelected = new Set(prevSelected);
            if (newSelected.has(unitId)) {
                newSelected.delete(unitId);
            } else {
                newSelected.add(unitId);
            }
            return newSelected;
        });
    };

    const handleExport = async () => {
        const selected = Array.from(selectedUnits);
        setIsLoading(true);
        try {
            const blob = await exportUnitCsv(selected);

            const now = new Date();
            const formattedDate = now.toLocaleDateString().split('/').reverse().join('-');
            const formattedTime = now.toLocaleTimeString().replace(/:/g, "-");

            const fileName = `units_${formattedDate}_${formattedTime}.csv`;

            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a);
            a.click();
            a.remove();

            setSelectedUnits(new Set());
        } catch (error) {
            console.error("Error exporting units:", error);
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <div>
            {selectedUnits.size > 0 && (
                <div className="d-flex align-items-center justify-content-between mb-3">
                    <div className="d-flex align-items-center ms-auto">
                        <span className="me-3">{selectedUnits.size} {t("labels.selected")}</span>
                        <CustomButton
                            title={t("buttons.csv_export")}
                            isLoading={isLoading}
                            onClick={handleExport}
                        />
                    </div>
                </div>
            )}
            <div className="unit-table-responsive">
                <table className="unit-table">
                    <thead>
                        <tr>
                            <th>
                                <input
                                    type="checkbox"
                                    onChange={(e) => {
                                        if (e.target.checked) {
                                            setSelectedUnits(new Set(units.map(u => u.unit_id)));
                                        } else {
                                            setSelectedUnits(new Set());
                                        }
                                    }}
                                    checked={selectedUnits.size === units.length}
                                />
                            </th>
                            <th onClick={() => handleSort(UnitSortBy.UNIT_NUMBER)} style={{ cursor: 'pointer' }}>
                                {t("unit_table.name_header")}
                                {selectedSortColumn === UnitSortBy.UNIT_NUMBER ? (
                                    selectedSortDirection === SortOrder.ASC ? <FaSortUp /> : <FaSortDown />
                                ) : <FaSort />}
                            </th>
                            <th onClick={() => handleSort(UnitSortBy.BEDROOM)} style={{ cursor: 'pointer' }}>
                                {t("unit_table.bedroom_header")}
                                {selectedSortColumn === UnitSortBy.BEDROOM ? (
                                    selectedSortDirection === SortOrder.ASC ? <FaSortUp /> : <FaSortDown />
                                ) : <FaSort />}
                            </th>
                            <th onClick={() => handleSort(UnitSortBy.SQUARE_FEET)} style={{ cursor: 'pointer' }}>
                                {t("unit_table.size_header")} (sq ft)
                                {selectedSortColumn === UnitSortBy.SQUARE_FEET ? (
                                    selectedSortDirection === SortOrder.ASC ? <FaSortUp /> : <FaSortDown />
                                ) : <FaSort />}
                            </th>
                            <th>
                                {t("unit_table.purpose_header")}´
                            </th>
                            <th>{t("unit_table.status_header")}</th>
                        </tr>
                    </thead>
                    <tbody>
                        {units.map(unit => (
                            <tr key={unit.unit_id} className="aktiv" onClick={() => onClickUnit(unit.unit_id)}>
                                <td>
                                    <input
                                        type="checkbox"
                                        checked={selectedUnits.has(unit.unit_id)}
                                        onChange={() => handleCheckboxChange(unit.unit_id)}
                                        onClick={(e) => e.stopPropagation()}
                                    />
                                </td>
                                <td>{unit.unit_number}</td>
                                <td>{unit.bedroom}</td>
                                <td>
                                    <span className="square-feet-badge">
                                        {unit.square_feet}
                                    </span>
                                </td>
                                <td>{formatUnitPurposeType(unit.purpose_type, t)}</td>
                                <td>
                                    <UnitStatusBadge status={unit.status} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    );
};

export default UnitTable;
