import { API_URL } from "../utils/constants";

export interface IConstructionUpdate {
  update_id: number;
  property_id: number;
  employee_id: number;
  title: string;
  thumbnail_url?: string;
  published: boolean;
  video_url: string | null;
  created_at: string;
  updated_at: string;
}

export const fetchConstructionUpdates = async (
  url: string
): Promise<IConstructionUpdate[]> => {
  const response = await fetch(url, {
    method: "GET",
    credentials: "include",
  });

  const data = await response.json();
  if (!response.ok) throw new Error(data.message);

  return data.updates;
};

export const constructConstructionUpdatesUrl = (
  propertyId: number,
  page?: number,
  pageSize?: number
): string => {
  let queryParams = new URLSearchParams();
  queryParams.append("propertyId", propertyId.toString());

  if (page !== undefined) {
    queryParams.append("page", page.toString());
  }
  if (pageSize !== undefined) {
    queryParams.append("pageSize", pageSize.toString());
  }

  return `${API_URL}/construction-updates?${queryParams.toString()}`;
};

export const fetchConstructionUpdate = async (
  updateId: number
): Promise<IConstructionUpdate> => {
  const response = await fetch(`${API_URL}/construction-updates/${updateId}`, {
    method: "GET",
    credentials: "include",
  });

  const data = await response.json();
  if (!response.ok) throw new Error(data.message);

  return data;
};

export const createConstructionUpdate = async (
  title: string,
  video_url: string | null,
  published: boolean,
  property_id: number
): Promise<IConstructionUpdate> => {
  const response = await fetch(`${API_URL}/construction-updates`, {
    method: "POST",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      title,
      video_url,
      published,
      property_id,
    }),
  });

  const data = await response.json();
  if (response.status === 201) return data;
  else throw new Error(data.message);
};

export const uploadConstructionUpdateThumbnail = async (
  update_id: number,
  thumbnail: File
): Promise<IConstructionUpdate> => {
  const formData = new FormData();

  // Append the picture file to the form data if provided
  formData.append(`file_thumbnail`, thumbnail);

  const response = await fetch(
    `${API_URL}/construction-updates/${update_id}/thumbnail`,
    {
      method: "POST",
      credentials: "include",
      body: formData,
    }
  );

  const data = await response.json();
  if (response.status === 201) return data;
  else throw new Error(data.message);
};

export const updateConstructionUpdate = async (
  updateId: number,
  title: string,
  video_url: string | null,
  published: boolean
): Promise<IConstructionUpdate> => {
  const response = await fetch(`${API_URL}/construction-updates/${updateId}`, {
    method: "PATCH",
    credentials: "include",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      title,
      video_url,
      published,
    }),
  });

  const data = await response.json();
  if (response.status === 200) return data;
  else throw new Error(data.message);
};

export const deleteConstructionUpdate = async (
  update_id: number
): Promise<boolean> => {
  const response = await fetch(`${API_URL}/construction-updates/${update_id}`, {
    method: "DELETE",
    credentials: "include",
  });

  return response.status === 200;
};
