// LeaseDetailPage.tsx
import "./lease-detail-page.css";

import _ from 'lodash';
import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import Lottie from "lottie-react";
import useAlertStore from "../../stores/alertStore";
import CustomButton from "../../components/custom-button/custom-button";
import { getFormattedDate } from "../../utils/helpers";
import { UploadedFile } from "../../components/file-upload/file-upload";
import { useTranslation } from "react-i18next";
import { IDocument } from "../../services/documentService";
import useEmployeeStore from "../../stores/employeeStore";
import LoadingAnim from "../../assets/anims/loading.-anim.json";
import DeleteAnim from "../../assets/anims/delete-anim.json";
import TenantDetails from "./components/tenant-details/tenant-details";
import DeleteConfirmationModal from "../../modals/delete-confirmation-modal/delete-confirmation-modal";
import useEditPermission from "../../hooks/useEditPermission";
import usePageTitle from "../../hooks/usePageTitle";
import { ILease, deleteLease, fetchLease, uploadLeaseDocuments } from "../../services/leaseService";
import useLeaseDetailStore from "../../stores/leaseDetailStore";
import PaymentDetails from "./components/payment-details/payment-details";
import { IPayment, PaymentPurpose } from "../../services/paymentService";
import FileUploadSection from "../../components/file-upload-section/file-upload-section";
import { EmployeeRole } from "../../services/employeeService";
import PaymentsTable from "./components/lease-payments-table/lease-payments-table";
import OccupantDetails from "./components/occupants-details/occupants-details";
import InvestorDetails from "./components/investor-details/investor-details";

const LeaseDetailPage = () => {
    let { leaseId } = useParams();
    const { t } = useTranslation();
    const navigate = useNavigate();

    // Stores
    const showAlert = useAlertStore(state => state.showAlert);
    const {
        lease,
        isLoading,
        isDeleting,
        setLease,
        setIsLoading,
        setIsDeleting,
    } = useLeaseDetailStore();
    const employeeRoleId = useEmployeeStore(state => state.employee?.role_id);

    const hasEditPermission = useEditPermission(employeeRoleId);
    const hasDeletePermission = useEditPermission(employeeRoleId, [EmployeeRole.ADMINISTRATOR]);

    // States
    const [leaseOriginal, setLeaseOriginal] = useState<ILease | null>(null);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [files, setFiles] = useState<UploadedFile[]>([]);
    const [internalFiles, setInternalFiles] = useState<UploadedFile[]>([]);
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(false);

    useEffect(() => {
        if (leaseId) {
            const loadLease = async () => {
                try {
                    setIsLoading(true);
                    const fechedLease = await fetchLease(Number(leaseId));
                    setLease(fechedLease);
                    setLeaseOriginal(fechedLease);
                } catch (error) {
                    console.log(`Error while fetching lease with id ${leaseId}:`, error);
                    // @ts-ignore
                    showAlert("error", error.message);
                } finally {
                    setIsLoading(false);
                }
            }
            loadLease();
        }
    }, [leaseId, setIsLoading, setLease, setLeaseOriginal, showAlert]);

    // Set page title
    usePageTitle(t("pages.lease_detail.page_title", { id: lease ? lease.lease_id : "" }));

    const onClickUpdateLease = useCallback(async () => {
        if (lease) {
            try {
                setIsUpdating(true);

                // Upload new documents
                if (files.length > 0 || internalFiles.length > 0) {
                    const allFiles = [
                        ...files.map(file => ({ ...file, internal_document: false })),
                        ...internalFiles.map(file => ({ ...file, internal_document: true }))
                    ];

                    const uploadedDocuments = await uploadLeaseDocuments(
                        lease.lease_id,
                        allFiles.map(file => file.file),
                        allFiles.map(file => file.internal_document ? "true" : "false")
                    );
                    const updatedDocuments = lease.documents;
                    updatedDocuments.push(...uploadedDocuments);
                    setLease({
                        ...lease,
                        documents: updatedDocuments
                    });
                    setFiles([]);
                    setInternalFiles([]);
                }

                setLeaseOriginal(lease);

                showAlert("success", t("pages.unit_detail.save_success_message"));
            } catch (error) {
                console.log(`Error while updating lease (id: ${leaseId}):`, error);
                // @ts-ignore
                showAlert("error", error.message);
            } finally {
                setIsUpdating(false);
            }
        }
    }, [lease, files, internalFiles, leaseId, setLease, showAlert, t]);

    const onDeleteLeaseDocument = useCallback(async (document: IDocument) => {
        if (lease) {
            const documents = lease.documents.filter(file => file.document_id !== document.document_id);
            setLease({ ...lease, documents });
            setLeaseOriginal({ ...lease, documents });
        }
    }, [lease, leaseOriginal, setLease]);

    const onDeleteLease = useCallback(async () => {
        try {
            setIsDeleteModalVisible(false);
            setIsDeleting(true);

            const leaseDeleted = await deleteLease(Number(leaseId));
            if (leaseDeleted) {
                navigate("/leases");
                showAlert("success", t("pages.lease_detail.delete_success_message"));
            }
        } catch (error) {
            console.log('Error while deleting lease:', error);
            // @ts-ignore
            showAlert("error", error.message);
        } finally {
            setIsDeleting(false);
        }
    }, [leaseId]);

    const dataHasBeenChanged = useMemo(() => {
        return !_.isEqual(lease, leaseOriginal) || files.length > 0 || internalFiles.length > 0;
    }, [lease, leaseOriginal, files, internalFiles]);

    const nextDueRent: IPayment | null = useMemo(() => {
        if (lease && lease.payments.length > 0) {
            const sortedRent = lease.payments
                .filter(payment => !payment.payment_date && payment.purpose === PaymentPurpose.RENT)
                .sort((a, b) => new Date(a.due_date).getTime() - new Date(b.due_date).getTime());
            if (sortedRent.length > 0) {
                return sortedRent[0];
            }
        }
        return null;
    }, [lease]);

    const receivedRent: number = useMemo(() => {
        if (lease && lease.payments.length > 0) {
            const paidRent = lease.payments
                .filter(payment => payment.payment_date && payment.purpose === PaymentPurpose.RENT);

            if (paidRent.length > 0) {
                return paidRent.reduce((total, rent) => {
                    const amount = Number(rent.amount) || 0;
                    return total + amount;
                }, 0);
            }
        }
        return 0;
    }, [lease]);

    return (
        <div className="no-select lease-detail-page">
            {isLoading || isDeleting ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '80vh' }}>
                    <Lottie animationData={isLoading ? LoadingAnim : DeleteAnim} loop={true} style={{ height: isLoading ? 100 : 150 }} />
                </div>
            ) : (
                lease && (
                    <>
                        {/* SECTION TITLE */}
                        <div className="d-flex justify-content-between align-items-center">
                            <div>
                                <div className="d-flex">
                                    <h4 className="headline m-0 me-2">Lease for Unit {lease.unit.unit_number} | {lease.unit.property.property_name}</h4>
                                    {/*<InvestorStatusBadge status={unit.status.status_name} />*/}
                                </div>
                                <p style={{ margin: 0, fontSize: 14 }}>{t("pages.unit_detail.subtitle", { date: getFormattedDate(lease.created_at) })} | ID: {lease.lease_id}</p>
                            </div>

                            {/* DESKTOP BUTTON */}
                            {hasEditPermission && (
                                <div className="d-none d-md-flex">
                                    {!isUpdating && hasDeletePermission && (
                                        <CustomButton
                                            className="d-none d-md-block me-3"
                                            title={t("buttons.delete")}
                                            color="red"
                                            isLoading={isDeleting}
                                            onClick={() => setIsDeleteModalVisible(true)}
                                        />
                                    )}
                                    <CustomButton
                                        className="d-none d-md-block"
                                        title={t("buttons.save")}
                                        isLoading={isUpdating}
                                        disabled={!dataHasBeenChanged}
                                        onClick={onClickUpdateLease}
                                    />
                                </div>
                            )}
                        </div>

                        {/* LEASE OVERVIEW */}
                        <>
                            <div className="row mt-2 gy-5">
                                {/* Payment details */}
                                <div className="col-12">
                                    <PaymentDetails
                                        payments={{
                                            total_rent: lease.total_rent_amount,
                                            security_deposit: lease.deposit_amount,
                                            rent_received: receivedRent,
                                            next_rent: nextDueRent,
                                        }}
                                    />
                                </div>

                                {/* Occupants, Tenant, and Investor Details */}
                                <div className="col-12 col-md-6 col-xl-4">
                                    <div className="col-12 col-md-12 mb-3">
                                        <OccupantDetails
                                            lease_id={lease.lease_id}
                                            occupants={lease.occupants}
                                            onOccupantCreated={(occupant) => {
                                                const updatedOccupants = lease.occupants;
                                                updatedOccupants.push(occupant);
                                                setLease({ ...lease, occupants: updatedOccupants });
                                                setLeaseOriginal(leaseOriginal ? { ...leaseOriginal, occupants: updatedOccupants } : null);
                                            }}
                                            onOccupantDeleted={(occupant_id) => {
                                                const updatedOccupants = lease.occupants.filter(occupant => occupant.occupant_id !== occupant_id);
                                                setLease({ ...lease, occupants: updatedOccupants });
                                                setLeaseOriginal(leaseOriginal ? { ...leaseOriginal, occupants: updatedOccupants } : null);
                                            }}
                                        />
                                    </div>
                                    <div className="col-12 col-md-12 mb-3">
                                        <TenantDetails
                                            tenant={lease.tenant}
                                            start_date={lease.start_date}
                                            end_date={lease.end_date}
                                        />
                                    </div>
                                    <div className="col-12 col-md-12 mb-3">
                                        <InvestorDetails owners={lease.unit.owners} />
                                    </div>
                                </div>

                                {/* Payments table - should appear below the details on small screens */}
                                <div className="col-12 col-xl-8">
                                    <PaymentsTable
                                        unit_account_id={lease.unit.unit_account.id}
                                        lease_id={lease.lease_id}
                                        payments={lease.payments.sort(
                                            (a, b) => new Date(a.due_date).getTime() - new Date(b.due_date).getTime()
                                        )}
                                        onPaymentsUpdated={(updatedPayments) => {
                                            setLease({ ...lease, payments: updatedPayments });
                                            setLeaseOriginal(leaseOriginal ? { ...leaseOriginal, payments: updatedPayments } : null);
                                        }}
                                    />
                                </div>

                                <div className="row">
                                    {/* DOCUMENT SECTION */}
                                    <div className="col-12 col-md-6">
                                        <FileUploadSection
                                            fileUploadId="lease-upload-other-documents"
                                            title={t("components.document_section.available_documents_title", { number: lease.documents.filter(document => !document.internal_document).length })}
                                            subtitle={t("components.document_section.provide_documents_subtitle")}
                                            documents={lease.documents.filter(document => document.internal_document === false)}
                                            uploadedFiles={files}
                                            onDeleteDocument={onDeleteLeaseDocument}
                                            onUpdateUploadedFiles={setFiles}
                                            hasEditPermission={hasEditPermission}
                                            isDefaultExpanded
                                        />
                                    </div>

                                    {/* INTERNAL DOCUMENT SECTION */}
                                    <div className="col-12 col-md-6">
                                        <FileUploadSection
                                            fileUploadId="lease-upload-internal-documents"
                                            title={t("components.document_section.internal_documents_title", { number: lease.documents.filter(document => document.internal_document).length })}
                                            subtitle={t("components.document_section.internal_documents_subtitle")}
                                            documents={lease.documents.filter(document => document.internal_document === true)}
                                            uploadedFiles={internalFiles}
                                            onDeleteDocument={onDeleteLeaseDocument}
                                            onUpdateUploadedFiles={setInternalFiles}
                                            hasEditPermission={hasEditPermission}
                                            isDefaultExpanded
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* MOBILE BUTTON */}
                            {hasEditPermission && (
                                <div className="d-md-none">
                                    <CustomButton
                                        className="mt-4 w-100"
                                        title={t("buttons.save")}
                                        isLoading={isUpdating}
                                        disabled={!dataHasBeenChanged}
                                        onClick={onClickUpdateLease}
                                    />
                                    {!isUpdating && hasDeletePermission && (
                                        <CustomButton
                                            className="mt-3 w-100"
                                            title={t("buttons.delete")}
                                            color="red"
                                            isLoading={isDeleting}
                                            onClick={() => setIsDeleteModalVisible(true)}
                                        />
                                    )}
                                </div>
                            )}
                        </>
                    </>
                )
            )}

            {/* DELETE CONFIRMATION MODAL */}
            {
                isDeleteModalVisible && hasDeletePermission && (
                    <DeleteConfirmationModal
                        title={t("pages.lease_detail.delete_modal.title")}
                        message={t("pages.lease_detail.delete_modal.message")}
                        onClose={() => setIsDeleteModalVisible(false)}
                        onDelete={onDeleteLease}
                    />
                )
            }
        </div >
    );
};

export default LeaseDetailPage;
