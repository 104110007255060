import './index.css';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import 'material-symbols';
import 'react-phone-number-input/style.css'
import './i18n';

import React from 'react';
import ReactDOM from 'react-dom/client';
import reportWebVitals from './reportWebVitals';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';

// Routes
import ErrorPage from './routes/error-page';
import Root from './routes/root';
import LoginPage from './routes/login-page/login-page';
import DashboardPage from './routes/dashboard-page';
import ConstructionUpdatesPage from './routes/construction-updates-page';
import InvestorsPage from './routes/investors-page/investors-page';
import ConstructionUpdatePage from './routes/construction-update-page/construction-update-page';
import ProtectedRoute from './routes/protected-route';
import PageContainerWrapper from './routes/page-container-wrapper';
import InvestorDetailPage from './routes/investor-detail-page/investor-detail-page';
import CoursePage from './routes/course-page';
import CourseDetailPage from './routes/course-detail-page/course-detail-page';
import * as Sentry from "@sentry/react";
import EmployeesPage from './routes/employees-page';
import TenantsPage from './routes/tenants-page/tenants-page';
import PropertiesPage from './routes/properties-page';
import UnitsPage from './routes/units-page/units-page';
import { EmployeeRole } from './services/employeeService';
import PropertyDetailPage from './routes/property-detail-page/property-detail-page';
import TenantDetailPage from './routes/tenant-detail-page/tenant-detail-page';
import UnitDetailPage from './routes/unit-detail-page/unit-detail-page';
import LeasesPage from './routes/leases-page/leases-page';
import ProfilePage from './routes/profile-page/profile-page';
import LeaseDetailPage from './routes/lease-detail-page/lease-detail-page';
import AuditLogsPage from './routes/audit-logs-page/audit-logs-page';
import AvailableUnitsPage from './routes/available-units-page/available-units-page';
import IndexPage from './routes/index-page';
import NewsPage from './routes/news-page/news-page';
import DocumentsPage from './routes/documents-page/documents-page';

const isProduction = process.env.REACT_APP_ENV === "production";

Sentry.init({
  dsn: "https://8fd24e5f914eeb12b88d7026d679900f@o4507089669849088.ingest.de.sentry.io/4507089672470608",
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  tracesSampleRate: 1.0,
  tracePropagationTargets: ["localhost", /^https:\/\/danielgarofoli\.com\/employee-api/],
  // Session Replay
  replaysSessionSampleRate: isProduction ? 0.1 : 1.0,
  replaysOnErrorSampleRate: 1.0,
});

const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    errorElement: <ErrorPage />,
    children: [
      {
        path: "/",
        element: <ProtectedRoute>
          <PageContainerWrapper id="index">
            <IndexPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/dashboard",
        element: <ProtectedRoute role_ids={[EmployeeRole.ADMINISTRATOR]}>
          <PageContainerWrapper id="dashboard">
            <DashboardPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/employees",
        element: <ProtectedRoute role_ids={[EmployeeRole.ADMINISTRATOR]}>
          <PageContainerWrapper id="employees">
            <EmployeesPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/audit-logs",
        element: <ProtectedRoute role_ids={[EmployeeRole.ADMINISTRATOR]}>
          <PageContainerWrapper id="audit-logs">
            <AuditLogsPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/investors",
        element: <ProtectedRoute role_ids={[EmployeeRole.ADMINISTRATOR, EmployeeRole.PROPERTY_MANAGER]}>
          <PageContainerWrapper id="investors">
            <InvestorsPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/documents",
        element: <ProtectedRoute role_ids={[EmployeeRole.ADMINISTRATOR]}>
          <PageContainerWrapper id="documents">
            <DocumentsPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/investors/:investorId",
        element: <ProtectedRoute role_ids={[EmployeeRole.ADMINISTRATOR, EmployeeRole.PROPERTY_MANAGER]}>
          <PageContainerWrapper id="investor-detail">
            <InvestorDetailPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/tenants",
        element: <ProtectedRoute role_ids={[EmployeeRole.ADMINISTRATOR, EmployeeRole.PROPERTY_MANAGER]}>
          <PageContainerWrapper id="tenants">
            <TenantsPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/tenants/:tenantId",
        element: <ProtectedRoute role_ids={[EmployeeRole.ADMINISTRATOR, EmployeeRole.PROPERTY_MANAGER]}>
          <PageContainerWrapper id="tenant-detail">
            <TenantDetailPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/properties",
        element: <ProtectedRoute>
          <PageContainerWrapper id="properties">
            <PropertiesPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/properties/:propertyId",
        element: <ProtectedRoute>
          <PageContainerWrapper id="property-detail">
            <PropertyDetailPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/properties/:propertyId/available-units",
        element: <ProtectedRoute role_ids={[EmployeeRole.ADMINISTRATOR, EmployeeRole.PROPERTY_MANAGER]}>
          <PageContainerWrapper id="property-detail-available-units">
            <AvailableUnitsPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/units",
        element: <ProtectedRoute role_ids={[EmployeeRole.ADMINISTRATOR, EmployeeRole.PROPERTY_MANAGER]}>
          <PageContainerWrapper id="units">
            <UnitsPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/units/:unitId",
        element: <ProtectedRoute role_ids={[EmployeeRole.ADMINISTRATOR, EmployeeRole.PROPERTY_MANAGER]}>
          <PageContainerWrapper id="unit-detail">
            <UnitDetailPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/leases",
        element: <ProtectedRoute>
          <PageContainerWrapper id="leases">
            <LeasesPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/leases/:leaseId",
        element: <ProtectedRoute>
          <PageContainerWrapper id="lease-detail">
            <LeaseDetailPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/construction-updates",
        element: <ProtectedRoute role_ids={[EmployeeRole.ADMINISTRATOR]}>
          <PageContainerWrapper id="construction-updates">
            <ConstructionUpdatesPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/construction-update/:updateId",
        element: <ProtectedRoute role_ids={[EmployeeRole.ADMINISTRATOR]}>
          <PageContainerWrapper id="construction-update">
            <ConstructionUpdatePage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/construction-update",
        element: <ProtectedRoute role_ids={[EmployeeRole.ADMINISTRATOR]}>
          <PageContainerWrapper id="construction-update">
            <ConstructionUpdatePage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/courses",
        element: <ProtectedRoute role_ids={[EmployeeRole.ADMINISTRATOR]}>
          <PageContainerWrapper id="courses">
            <CoursePage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/course",
        element: <ProtectedRoute role_ids={[EmployeeRole.ADMINISTRATOR]}>
          <PageContainerWrapper id="course">
            <CourseDetailPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/course/:itemId",
        element: <ProtectedRoute role_ids={[EmployeeRole.ADMINISTRATOR]}>
          <PageContainerWrapper id="course">
            <CourseDetailPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/news",
        element: <ProtectedRoute role_ids={[EmployeeRole.ADMINISTRATOR]}>
          <PageContainerWrapper id="news">
            <NewsPage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/profile",
        element: <ProtectedRoute>
          <PageContainerWrapper id="profile">
            <ProfilePage />
          </PageContainerWrapper>
        </ProtectedRoute>,
      },
      {
        path: "/login",
        element: <LoginPage />,
      },
    ],
  },
]);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
