import useSWRInfinite from 'swr/infinite';
import { constructDocumentsUrl, DocumentStatus, fetchDocuments, IDocument } from '../services/documentService';

const PAGE_SIZE = 20;

export const useDocuments = (
    status: DocumentStatus | null,
    search: string,
) => {
    const fetcher = (url: string) => fetchDocuments(url);

    const getKey = (pageIndex: number, previousPageData: IDocument[] | null) => {
        if (previousPageData && !previousPageData.length) return null;
        return constructDocumentsUrl(status, search, pageIndex + 1, PAGE_SIZE);
    };

    const { data, error, size, setSize, mutate } = useSWRInfinite<IDocument[]>(getKey, fetcher);

    const isLoadingInitialData = !data && !error;
    const isLoadingMore = isLoadingInitialData || (size > 0 && data && typeof data[size - 1] === "undefined");

    return {
        documents: data ? ([] as IDocument[]).concat(...data) : [],
        isLoading: isLoadingInitialData,
        isLoadingMore,
        size,
        setSize,
        mutate,
        isReachingEnd: data && data[data.length - 1]?.length < PAGE_SIZE,
        error,
    };
};
