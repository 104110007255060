import "./construction-update-page.css";

import { useCallback, useEffect, useMemo, useState } from "react";
import _ from 'lodash';
import CustomButton from "../../components/custom-button/custom-button";
import CustomInputField from "../../components/custom-input-field/custom-input-field";
import FileUpload, { UploadedFile } from "../../components/file-upload/file-upload";
import { IoAdd, IoCloseCircle, IoTrashBin } from "react-icons/io5";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { IConstructionUpdate, createConstructionUpdate, fetchConstructionUpdate, updateConstructionUpdate, uploadConstructionUpdateThumbnail } from "../../services/constructionUpdateService";
import useAlertStore from "../../stores/alertStore";
import DeleteConstructionUpdateModal from "../../modals/delete-construction-update-modal/delete-construction-update-modal";
import { getYouTubeId, isYouTubeUrl } from "../../utils/helpers";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { useTranslation } from "react-i18next";
import { useConstructionUpdates } from "../../hooks/useConstructionUpdates";
import Lottie from "lottie-react";
import LoadingAnim from "../../assets/anims/loading.-anim.json";
import usePageTitle from "../../hooks/usePageTitle";
import { SPACES_ENDPOINT } from "../../utils/constants";

const ConstructionUpdatePage = () => {
    const { t } = useTranslation();
    const location = useLocation();
    const navigate = useNavigate();
    let { updateId } = useParams();

    // Params
    const searchParams = new URLSearchParams(location.search);
    const propertyName = decodeURIComponent(searchParams.get('propertyName') || '');
    const propertyId = searchParams.get('propertyId');

    // Set page title
    usePageTitle(t("pages.construction_update.title", { name: propertyName }));

    // Stores
    const showAlert = useAlertStore(state => state.showAlert);

    // States
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [isUpdating, setIsUpdating] = useState<boolean>(false);
    const [constructionUpdate, setConstructionUpdate] = useState<IConstructionUpdate>({
        update_id: 0,
        property_id: Number(propertyId),
        employee_id: 0,
        title: "",
        picture_path: "",
        video_url: null,
        published: false,
        created_at: new Date().toString(),
        updated_at: new Date().toString(),
    } as IConstructionUpdate);
    const [constructionUpdateOriginal, setConstructionUpdateOriginal] = useState<IConstructionUpdate>({
        update_id: 0,
        property_id: Number(propertyId),
        employee_id: 0,
        title: "",
        video_url: null,
        published: false,
        created_at: new Date().toString(),
        updated_at: new Date().toString(),
    } as IConstructionUpdate);
    const [titleError, setTitleError] = useState('');
    const [videoUrlError, setVideoUrlError] = useState('');
    const [thumbnail, setThumbnail] = useState<UploadedFile | null>(null);
    const [error, setError] = useState('');
    const [isDeleteModalVisible, setIsDeleteModalVisible] = useState<boolean>(false);

    // Hooks
    const { mutate } = useConstructionUpdates(Number(propertyId));

    useEffect(() => {
        if (error) {
            showAlert("error", error);
        }
    }, [error, showAlert]);

    useEffect(() => {
        if (updateId) {
            const fetchUpdate = async () => {
                try {
                    setIsLoading(true);
                    const update = await fetchConstructionUpdate(Number(updateId));
                    setConstructionUpdate(update);
                    setConstructionUpdateOriginal(update)
                } catch (error) {
                    console.log("Error while fetching construction update:", error);
                    // @ts-ignore
                    showAlert("error", error.message);
                } finally {
                    setIsLoading(false);
                }
            }
            fetchUpdate();
        }
    }, [updateId, showAlert]);

    const onFileSelected = (newFile: UploadedFile[]) => {
        const newImage = {
            ...newFile[0],
            url: URL.createObjectURL(newFile[0].file),
        };

        setThumbnail(newImage);
    };

    const onClickSave = useCallback(async () => {
        let isValid = true;

        // Check if the title is not empty
        if (!constructionUpdate.title.trim()) {
            setTitleError(t("invalid_input.title_empty"));
            isValid = false;
        }

        // Check if the video_url is not empty
        if (!constructionUpdate.video_url) {
            setVideoUrlError(t("invalid_input.video_url_empty"));
            isValid = false;
        }

        // Check if minimum one image was selected
        if (!constructionUpdate.thumbnail_url && !thumbnail) {
            showAlert("error", t("pages.construction_update.min_picture_not_uploaded_message"));
            isValid = false;
        }

        if (isValid && propertyId) {
            try {
                setError("");
                setIsUpdating(true);

                if (updateId) {
                    await updateConstructionUpdate(Number(updateId), constructionUpdate.title, constructionUpdate.video_url ?? null, constructionUpdate.published);

                    if (thumbnail) {
                        const updadatedConstructionUpdate = await uploadConstructionUpdateThumbnail(Number(updateId), thumbnail.file);
                        setConstructionUpdate(updadatedConstructionUpdate);
                        setThumbnail(null);
                    }
                    showAlert("success", t("pages.construction_update.updated_success_message"));
                } else {
                    let createdUpdate = await createConstructionUpdate(constructionUpdate.title, constructionUpdate.video_url ?? null, constructionUpdate.published, Number(propertyId));
                    createdUpdate = await uploadConstructionUpdateThumbnail(createdUpdate.update_id, thumbnail!.file);

                    mutate(
                        (data: IConstructionUpdate[][] | undefined) => {
                            if (!data) return [];
                            return [
                                [createdUpdate, ...data[0]],
                                ...data.slice(1)
                            ];
                        },
                        false
                    );

                    showAlert("success", t("pages.construction_update.created_success_message"));
                    navigate("/construction-updates");
                }
            } catch (error) {
                // @ts-ignore
                showAlert("error", error.message);
            } finally {
                setIsUpdating(false);
            }
        }
    }, [constructionUpdate, propertyId, thumbnail]);

    const onDeleteConstructionUpdate = useCallback(() => {
        if (constructionUpdate) {
            mutate(
                (data: IConstructionUpdate[][] | undefined) => {
                    if (!data) return [];
                    return data.map(page => page.filter(item => item.update_id !== Number(updateId)));
                },
                false
            );

            navigate("/construction-updates");
            showAlert("success", t("pages.construction_update.deleted_success_message"));
        }
    }, [constructionUpdate, t, updateId])

    const onChangeStatusHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
        setConstructionUpdate({ ...constructionUpdate, published: e.target.value === "true" ? true : false });
    }

    const dataHasBeenChanged = useMemo(() => {
        if (!updateId) return true;
        return !_.isEqual(constructionUpdate, constructionUpdateOriginal) || thumbnail;
    }, [constructionUpdate, constructionUpdateOriginal, thumbnail, updateId]);

    return (
        <div className="no-select">
            {isLoading ? (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '80vh' }}>
                    <Lottie animationData={LoadingAnim} loop={true} style={{ height: 100 }} />
                </div>
            ) : (
                <>
                    {/* SECTION TITLE */}
                    <div className="d-flex flex-column flex-lg-row justify-content-lg-between align-items-start align-items-lg-center">
                        <h4 className="mb-3 mb-lg-0">{t("pages.construction_update.title", { name: propertyName })}</h4>
                        <div className="d-flex">
                            {updateId && (
                                <CustomButton
                                    className="me-3"
                                    title={t("buttons.delete")}
                                    color="red"
                                    icon={IoTrashBin}
                                    isLoading={false}
                                    disabled={isUpdating}
                                    onClick={() => setIsDeleteModalVisible(true)}
                                />
                            )}
                            <CustomButton
                                title={updateId ? t("buttons.save") : t("buttons.create")}
                                icon={IoAdd}
                                isLoading={isUpdating}
                                disabled={!dataHasBeenChanged}
                                onClick={onClickSave}
                            />
                        </div>
                    </div>

                    <h4 className="mt-5">{t("pages.construction_update.general_information_title")}</h4>
                    <div className="row mt-1 gy-3">
                        <div className="col-12 col-md-6">
                            <div className="white-card h-100">
                                <p>Status *</p>
                                <div className="d-flex mb-3">
                                    <div className="form-check">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" value="true" id="flexRadioDefault1" checked={constructionUpdate.published} onChange={onChangeStatusHandler} />
                                        <label className="form-check-label" htmlFor="flexRadioDefault1">
                                            {t("pages.construction_update.state_active")}
                                        </label>
                                    </div>
                                    <div className="form-check ms-3">
                                        <input className="form-check-input" type="radio" name="flexRadioDefault" value="false" id="flexRadioDefault2" checked={!constructionUpdate.published} onChange={onChangeStatusHandler} />
                                        <label className="form-check-label" htmlFor="flexRadioDefault2">
                                            {t("pages.construction_update.state_inactive")}
                                        </label>
                                    </div>
                                </div>
                                <CustomInputField
                                    id="title"
                                    label={t("labels.title")}
                                    maxLength={60}
                                    value={constructionUpdate.title}
                                    errorMessage={titleError}
                                    onChange={(text: string) => {
                                        setConstructionUpdate({ ...constructionUpdate, title: text });
                                        setTitleError("");
                                    }}
                                    onClear={() => {
                                        setConstructionUpdate({ ...constructionUpdate, title: "" });
                                        setTitleError("");
                                    }}
                                    placeholder={t("pages.construction_update.title_placeholder")}
                                    required
                                />
                                <CustomInputField
                                    id="video-url"
                                    label={t("labels.video_url")}
                                    maxLength={255}
                                    value={constructionUpdate.video_url ?? ""}
                                    errorMessage={videoUrlError}
                                    onChange={(text: string) => {
                                        setConstructionUpdate({ ...constructionUpdate, video_url: text });
                                        setVideoUrlError("");
                                    }}
                                    onClear={() => {
                                        setConstructionUpdate({ ...constructionUpdate, video_url: "" });
                                        setVideoUrlError("");
                                    }}
                                    placeholder="z.B. https://www.youtube.com/watch?v=6Y31C1jUqg0"
                                    required
                                />
                                {constructionUpdate.video_url && constructionUpdate.video_url.length > 0 && (
                                    <>
                                        <p className="my-0">{t("pages.construction_update.preview")}</p>
                                        <div className="white-card video-wrapper">
                                            <div className="video-responsive">
                                                <iframe
                                                    width="560"
                                                    height="315"
                                                    src={isYouTubeUrl(constructionUpdate.video_url) ?
                                                        `https://www.youtube.com/embed/${getYouTubeId(constructionUpdate.video_url)}` :
                                                        constructionUpdate.video_url}
                                                    title="YouTube video player"
                                                    frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen
                                                />
                                            </div>
                                        </div>
                                    </>
                                )}
                            </div>
                        </div>
                        <div className="col-12 col-md-6">
                            <div className="white-card h-100">
                                {!constructionUpdate.thumbnail_url ? (
                                    <>
                                        <h4 className="mb-3">{updateId ? t("pages.construction_update.thumbnail_title") : `${t("pages.construction_update.new_thumbnail_title")} *`}</h4>
                                        {thumbnail ? (
                                            <div className="image-square">
                                                <LazyLoadImage src={URL.createObjectURL(thumbnail.file)} alt="Thumbnail" className="img-fluid" effect="opacity" />
                                                <IoCloseCircle className="remove-icon" onClick={() => setThumbnail(null)} />
                                            </div>
                                        ) : (
                                            <FileUpload
                                                id="construction-update-pictures"
                                                style={{ height: "100%" }}
                                                allowedTypes="image/*"
                                                maxFilesToUpload={1}
                                                onFileSelected={onFileSelected}
                                                currentFileCount={thumbnail ? 1 : 0}
                                            />
                                        )}
                                    </>
                                ) : (
                                    <div className="image-square">
                                        <LazyLoadImage src={`${SPACES_ENDPOINT}${constructionUpdate.thumbnail_url}`} alt="Thumbnail" className="img-fluid" effect="opacity" />
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>

                    {isDeleteModalVisible && (
                        <DeleteConstructionUpdateModal
                            update_id={Number(updateId)}
                            onClose={(deleted: boolean) => {
                                if (deleted) {
                                    onDeleteConstructionUpdate();
                                }
                                setIsDeleteModalVisible(false);
                            }}
                        />
                    )}
                </>
            )}
        </div>
    );
};

export default ConstructionUpdatePage;
