import '../base-modal.css';
import './review-document-modal.css';

import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SidebarInputField from '../sidebar/components/sidebar-input-field/sidebar-input-field';
import { DocumentStatus, downloadDocument, IDocument, updateDocument } from '../../services/documentService';
import { Document, Page, pdfjs } from 'react-pdf';

// Import Bootstrap Spinner
import { Spinner } from 'react-bootstrap';

// PDF worker setup for react-pdf
pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

interface ModalProps {
    doc: IDocument;
    onClose: () => void;
    onAccepted: () => void;
    onDeclined: (reason: string) => void;
}

const ReviewDocumentModal: React.FC<ModalProps> = ({ doc, onClose, onAccepted, onDeclined }) => {
    const { t } = useTranslation();

    // States
    const [isDownloading, setIsDownloading] = useState<boolean>(true);
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [reason, setReason] = useState<string>("");
    const [reasonError, setReasonError] = useState<string>("");
    const [fileUrl, setFileUrl] = useState<string | null>(null);
    const [fileType, setFileType] = useState<string | null>(null);
    const [numPages, setNumPages] = useState<number>(0);
    const [pageNumber, setPageNumber] = useState<number>(1); // Default page for PDFs

    // Load the document when the modal opens
    useEffect(() => {
        const loadFile = async () => {
            if (doc) {
                try {
                    setIsDownloading(true);

                    const blob: Blob | null = await downloadDocument(doc.document_id);
                    if (blob) {
                        const blobUrl = window.URL.createObjectURL(blob);
                        setFileUrl(blobUrl);

                        // Determine file type
                        if (blob.type.includes('pdf')) {
                            setFileType('pdf');
                        } else if (blob.type.includes('image')) {
                            setFileType('image');
                        } else {
                            setFileType('unsupported');
                        }
                    }
                } catch (error) {
                    console.error("Error while downloading file: ", error);
                } finally {
                    setIsDownloading(false);
                }
            }
        };
        loadFile();
    }, [doc]);

    // Approve Document
    const onHandleAcceptDocument = useCallback(async () => {
        try {
            setIsLoading(true);
            await updateDocument(doc.document_id, DocumentStatus.ACCEPTED);
            onAccepted();
        } catch (error) {
            console.error(`Error while accepting document (${doc.document_id}):`, error);
        } finally {
            setIsLoading(false);
        }
    }, [doc, onAccepted]);

    // Decline Document
    const onHandleDeclineDocument = useCallback(async () => {
        let isValid = true;

        if (!reason.trim()) {
            setReasonError(t("invalid_input.reason_empty"));
            isValid = false;
        }

        if (isValid) {
            try {
                setIsLoading(true);
                await updateDocument(doc.document_id, DocumentStatus.DECLINED, reason);
                onDeclined(reason);
            } catch (error) {
                console.error(`Error while declining document (${doc.document_id}):`, error);
            } finally {
                setIsLoading(false);
            }
        }
    }, [reason, doc, t, onDeclined]);

    // Render File Preview
    const renderFilePreview = () => {
        const previewStyle: React.CSSProperties = {
            maxHeight: "500px", // Set max height
            overflowY: "auto",  // Enable vertical scroll
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        };

        if (fileType === 'pdf') {
            return (
                <div className="pdf-container" style={previewStyle}>
                    <Document
                        file={fileUrl}
                        onLoadSuccess={({ numPages }) => setNumPages(numPages)}
                    >
                        <Page pageNumber={pageNumber} />
                    </Document>
                    <div className="pdf-controls">
                        <button disabled={pageNumber <= 1} onClick={() => setPageNumber(pageNumber - 1)}>←</button>
                        <span>{pageNumber} / {numPages}</span>
                        <button disabled={pageNumber >= numPages} onClick={() => setPageNumber(pageNumber + 1)}>→</button>
                    </div>
                </div>
            );
        } else if (fileType === 'image') {
            return (
                <div style={previewStyle}>
                    <img
                        src={fileUrl!}
                        alt="Document Preview"
                        style={{
                            maxWidth: '100%',
                            maxHeight: "100%",
                            objectFit: "contain"
                        }}
                    />
                </div>
            );
        } else {
            return <p>{t("modals.review_document.unsupported_file_type")}</p>;
        }
    };

    return (
        <div className="modal-backdrop">
            <div className="modal-box">
                <div className="d-flex align-items-center justify-content-between mb-4">
                    <h3 className="modal-title">{t("modals.review_document.title")}</h3>
                    <span
                        style={{
                            cursor: "pointer",
                            fontSize: "1.8rem",
                            lineHeight: "1",
                            display: "flex",
                            alignItems: "center",
                        }}
                        onClick={onClose}
                    >
                        &times;
                    </span>
                </div>

                {/* File Preview Section */}
                <div className="file-preview-container mb-4">
                    {isDownloading ? (
                        <div className="d-flex justify-content-center align-items-center" style={{ height: '300px' }}>
                            <Spinner animation="border" variant="primary" />
                        </div>
                    ) : (
                        renderFilePreview()
                    )}
                </div>

                {/* Decline Reason Input */}
                <SidebarInputField
                    id="reason-for-refusal"
                    type="text"
                    label={t("labels.reason_for_refusal")}
                    placeholder={t("placeholders.reason_for_refusal")}
                    value={reason}
                    errorMessage={reasonError}
                    onClear={() => setReason("")}
                    onChange={(value: string) => {
                        setReason(value);
                        setReasonError("");
                    }}
                    labelOnTop
                    required
                />

                {/* Buttons */}
                {!isDownloading && (
                    <div className="text-end mt-4">
                        <button
                            className={`btn btn-success me-2 ${isLoading ? 'disabled' : ''}`}
                            onClick={onHandleAcceptDocument}
                            disabled={isLoading}
                        >
                            {isLoading ? <Spinner size="sm" /> : t("buttons.accept")}
                        </button>
                        <button
                            className={`btn btn-danger me-2 ${isLoading ? 'disabled' : ''}`}
                            onClick={onHandleDeclineDocument}
                            disabled={isLoading}
                        >
                            {isLoading ? <Spinner size="sm" /> : t("buttons.decline")}
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ReviewDocumentModal;
